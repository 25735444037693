/* HomePage container styling */
.home-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; /* Center both elements vertically */
    height: 100vh; /* Full screen height */
    background-color: #f4f4f4; /* Light background for contrast */
    padding: 20px;
  }
  
  /* Styling for the back button (Terug) */
  .btn-back {
    padding: 12px 20px;
    font-size: 18px;
    background-color: #ff6347; /* Change color to Tomato (red) */
    color: white;
    border: 2px solid #ff6347;
    border-radius: 5px; /* Rounded corners */
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 40px;
    margin-bottom: 10px; /* Reduce the margin below the Terug button */
    align-self: center; /* Center the button horizontally */
    width: 60%; /* Set the button width to 60% */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .btn-back:hover {
    background-color: #ff4500; /* Darker shade on hover */
  }
  
  /* Input container to stack the input and confirm button vertically */
  .input-container {
    display: flex;
    flex-direction: column; /* Stack the input and button vertically */
    align-items: center;
    width: 100%;
    max-width: 400px; /* Set max-width for the container */
  }
  
  /* Styling for the barcode input field */
  .barcode-input {
    padding: 12px;
    font-size: 18px;
    width: 100%; /* Make the input take the full width of the container */
    border: 2px solid #ccc;
    border-radius: 5px; /* Rounded corners */
    outline: none;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px; /* Add some space between the input and the button */
    box-sizing: border-box;
  }
  
  /* Styling for the confirm button */
  .btn-confirm {
    padding: 12px 20px;
    font-size: 18px;
    width: 60%; /* Set the button width to 60% */
    background-color: #28a745;
    color: white;
    border: 2px solid #28a745;
    border-radius: 5px; /* Rounded corners to match the input */
    cursor: pointer;
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .btn-confirm:hover {
    background-color: #218838;
  }
  