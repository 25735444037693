body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
  margin: 0;
  padding: 0;
}

.container {
  padding: 10px; /* Reduce padding inside the container */
  margin: 0 auto; /* Center the container, reducing margin */
  width: 100%; /* Ensure the container takes full width */
  box-sizing: border-box;
}

.App-header {
  background-color: #007bff;
  padding: 10px;
  color: white;
  text-align: center;
  width: 100%
}

.App-header h4 {
  margin: 0;
  font-size: 1.4em;
}
.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full screen height */
}

.barcode-input {
  padding: 10px;
  font-size: 16px;
  width: 80%;
  max-width: 300px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.btn-confirm {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}

.btn-confirm:hover {
  background-color: #0056b3;
}

.btn-back {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.btn-back:hover {
  background-color: #5a6268;
}

