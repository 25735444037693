.article-card {
  border: 1px solid #ccc;
  padding: 15px; /* Reduce padding to 10px */
  margin: 10px auto; /* Reduce margin to 10px */
  border-radius: 10px;
  background-color: #f9f9f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Reduce shadow for a more subtle effect */
  width: 90%; /* Increase the width to 90% */
  max-width: 110%; /* Reduce max-width to 600px */
  min-width: 100%; /* Set a minimum width to 250px */
  box-sizing: border-box;
  overflow-x: hidden;
}
.article-card {
  margin-left: 0px; /* Reduce the top margin */
  margin-left: 0px; /* Reduce the bottom margin */
}
.article-card h3 {
  margin-top: 0;
}

.article-details p,
.alternative-location p {
  word-wrap: break-word;
  margin: 3px 0; /* Reduce the margin between paragraphs */
}

.collapsible {
  margin-top: 5px; /* Reduce top margin */
}

.collapsible .Collapsible__contentInner {
  padding: 8px; /* Reduce padding */
  border: 1px solid #ddd;
  border-radius: 5px;
  background-color: #fff;
}

.collapsible-trigger {
  display: inline-block;
  cursor: pointer;
  padding: 8px; /* Reduce padding */
  margin: 8px 0; /* Reduce margin */
  background-color: #007bff;
  color: white;
  border-radius: 5px;
  text-align: center;
}

.collapsible-trigger:hover {
  background-color: #0056b3;
}

.collapsible-trigger.active {
  background-color: #0056b3;
  font-weight: bold;
}

.collapsible.disabled p {
  color: #999; 
  cursor: not-allowed; 
  background-color: #e0e0e0; 
  padding: 8px; /* Reduce padding */
  border-radius: 5px;
  text-align: center;
}

.alternative-location {
  border-top: 1px solid #eee;
  padding: 8px 0; /* Reduce padding */
}

.input-group {
  margin-top: 10px; /* Reduce top margin */
  display: flex;
  flex-direction: column;
  align-items: center; 
}

.input-quantity {
  padding: 8px; /* Reduce padding */
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 90%; /* Increase width to 90% */
  max-width: 180px; /* Reduce max-width to 180px */
  margin-bottom: 8px; /* Reduce margin-bottom */
}

.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 250px; /* Reduce max-width */
  margin-bottom: 8px; /* Reduce margin-bottom */
}

.button-group-single {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 250px; /* Reduce max-width */
  margin-bottom: 8px; /* Reduce margin-bottom */
}

.btn-confirm,
.btn-process,
.btn-check {
  padding: 8px 15px; /* Reduce padding */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  margin: 0 3px; /* Reduce margin between buttons */
}

.btn-confirm {
  background-color: #28a745;
  color: white;
}

.btn-confirm:hover {
  background-color: #218838;
}

.btn-process {
  background-color: #007bff;
  color: white;
}

.btn-process:hover {
  background-color: #0056b3;
}

.btn-check {
  background-color: #ffc107;
  color: white;
}

.btn-check:hover {
  background-color: #e0a800;
}

.picked-quantity-display {
  margin-top: 8px; /* Reduce top margin */
  font-weight: bold;
}

.error-message {
  color: red;
  font-weight: bold;
  margin-top: 8px; /* Reduce top margin */
}
.button-group {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 300px;
  margin-bottom: 10px;
}

.btn-default {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #28a745;
  color: white;
  flex: 1;
  margin-right: 5px;
}

.btn-default:hover {
  background-color: #218838;
}

.btn-anders {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  flex: 1;
}

.btn-anders:hover {
  background-color: #0056b3;
}

.anders-input-group {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.anders-input {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 130px;
  margin-right: 5px;
}

.btn-check-mark {
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
}
/* Loader.css */
.fullscreen-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure it's above other elements */
}

.spinner {
  border: 8px solid rgba(255, 255, 255, 0.3); /* Light border */
  border-top: 8px solid white; /* Solid white spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btn-check-mark-edit {
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: red;
  color: white;
}

.btn-check-mark:hover {
  background-color: #007bff;
}
.btn-anders {
  background-color: #007bff; /* Default button color */
  color: white;
  border: none;
  padding: 8px 16px;
  cursor: pointer;
}

.btn-anders:disabled {
  background-color: #d3d3d3; /* Grey color for disabled state */
  color: #888; /* Lighter text color for disabled state */
  cursor: not-allowed; /* Show a not-allowed cursor */
}

.btn-default:disabled{
  background-color: #d3d3d3; /* Grey color for disabled state */
  color: #888; /* Lighter text color for disabled state */
  cursor: not-allowed; /* Show a not-allowed cursor */
}

/* Style for the new edit button */
.btn-edit {
  background-color: gray;
  color: black;
  border: none;
  padding: 10px 20px;
  font-size: 14px;
  cursor: pointer;
  border-radius: 5px;
  margin-left: 50px; /* Adjust space between "Anders" and "Edit" */
}

.btn-edit:hover {
  background-color: darkgray;
}

.edit-icon {
  margin-right: 5px;
}

/* Align button group to the right */
.button-group .btn-edit {
  margin-right: -30px;
  margin-right: 5px;
}