.unauthenticated-container {
    text-align: center;
    padding: 50px;
    background-color: #f8f9fa;
    border-radius: 10px;
    margin: 20px auto;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .unauthenticated-icon {
    font-size: 50px;
    margin-bottom: 20px;
  }
  
  .unauthenticated-title {
    font-size: 24px;
    color: #dc3545;
    margin-bottom: 10px;
    font-weight: bold;
  }
  
  .unauthenticated-text {
    font-size: 18px;
    color: #343a40;
  }
  
  .unauthenticated-text strong {
    color: #007bff;
  }
  